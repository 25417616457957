import React, { useRef } from "react"
import "./card.scss"
// import { useIntersection } from "react-use"
// import gsap from "gsap"

const Card = ({ children, rootMargin, title }) => {
  const wrapper = useRef(null)
  // const isInitialMount = useRef(true)

  // const intersection = useIntersection(wrapper, {
  //   root: null,
  //   rootMargin: "0px",
  //   threshold: 1,
  // })

  // useEffect(() => {
  //   if (isInitialMount.current) {
  //     isInitialMount.current = false
  //   } else {
  //     const elements = [...wrapper.current.children]
  //     if (intersection && intersection.intersectionRatio < 1) {
  //       gsap.fromTo(
  //         elements,
  //         { x: "0", alpha: 1 },
  //         { x: "-500", alpha: 0, duration: 0.2 }
  //       )
  //     } else {
  //       const tl = gsap.timeline({
  //         defaults: { ease: "none" },
  //       })

  //       elements.forEach(item => {
  //         tl.fromTo(
  //           item,
  //           {
  //             x: "-500",
  //             alpha: 0,
  //           },
  //           { x: "0", alpha: 1, duration: 0.2 }
  //         )
  //       })
  //     }
  //   }
  // }, [intersection])

  return (
    <div className="card" ref={wrapper}>
      <h3>{title}</h3>
      <div className="card-body">{children}</div>
    </div>
  )
}
export default Card
