import React, { useRef } from "react"
import "./nav.scss"
import { Link } from "gatsby"

const Nav = ({ menuOpened, switchMenu }) => {
  const navContainer = useRef()

  const changeBackground = background => {
    if (background === "projects") {
      navContainer.current.style.background = "#2610ff"
    } else if (background === "about") {
      navContainer.current.style.background = "#8600f6"
    } else if (background === "contact") {
      navContainer.current.style.background = "#660060"
    } else if (background === "none") {
      navContainer.current.style.background = "rgba(0, 0, 0, 0.9)"
    }
  }

  return (
    <>
      {menuOpened ? (
        <nav className="nav" ref={navContainer}>
          <ul className="nav__list">
            <li>
              <Link
                to="/"
                className="nav__link"
                onMouseEnter={changeBackground.bind(null, "projects")}
                onMouseLeave={changeBackground.bind(null, "none")}
              >
                Projects
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                className="nav__link"
                onMouseEnter={changeBackground.bind(null, "about")}
                onMouseLeave={changeBackground.bind(null, "none")}
              >
                About me
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                className="nav__link"
                onMouseEnter={changeBackground.bind(null, "contact")}
                onMouseLeave={changeBackground.bind(null, "none")}
              >
                Contact
              </Link>
            </li>
          </ul>
          <div className="nav__footer">© 2020 Krystian Fluder</div>
        </nav>
      ) : null}
    </>
  )
}

export default Nav
