import React from "react"
import "./footer.scss"
import { useStaticQuery, graphql } from "gatsby"

import { Link } from "gatsby"
import Card from "../../components/card/card"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        nodes {
          frontmatter {
            slug
            title
          }
        }
      }
    }
  `)

  const projects = data.allMarkdownRemark.nodes

  return (
    <footer className="footer">
      <Card title="Contact information">
        <p>
          Email:{" "}
          <a href="mailto:krystian.fluder@outlook.com">
            krystian.fluder@outlook.com
          </a>
        </p>
        <p>
          Github:{" "}
          <a
            href="https://github.com/krystianfluder"
            target="_blank"
            rel="noreferrer"
          >
            https://github.com/krystianfluder
          </a>
        </p>
      </Card>
      <Card title="Latest projects">
        {projects.map(({ frontmatter }) => (
          <Link key={frontmatter.slug} to={`/project/${frontmatter.slug}`}>
            {frontmatter.title}
          </Link>
        ))}
      </Card>
    </footer>
  )
}

export default Footer
