import React from "react"
import "./header.scss"
import { Link } from "gatsby"
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai"

const Header = ({ switchMenu, menuOpened }) => {
  let classList = ["header"]
  if (menuOpened) {
    classList.push("active")
  }
  classList = classList.join(" ")

  return (
    <header className={classList}>
      <Link to="/">
        <p>Krystian Fluder</p>
        <p>MERN Stack Developer</p>
      </Link>
      {menuOpened ? (
        <AiOutlineClose
          size={32}
          onClick={switchMenu}
          className="header__close"
        />
      ) : (
        <AiOutlineMenu
          onClick={switchMenu}
          size={32}
          className="header__burger"
        />
      )}
    </header>
  )
}

export default Header
