import React, { useState } from "react"
import PropTypes from "prop-types"
import "./layout.scss"
import SEO from "../components/seo"
import Container from "./container/container"
import Nav from "./nav/nav"
import Footer from "./footer/footer"
import Header from "./header/header"

import "../css/typography.css"
import "../css/main.css"

const Layout = ({ children, title, description }) => {
  const [menuOpened, setMenuOpened] = useState(false)

  const switchMenu = () => {
    setMenuOpened(prev => !prev)
  }

  return (
    <div className="layout">
      <SEO title={title} description={description} />
      <Nav menuOpened={menuOpened} switchMenu={switchMenu} />
      <div className="content">
        <Header menuOpened={menuOpened} switchMenu={switchMenu} />
        <Container menuOpened={menuOpened} switchMenu={switchMenu}>
          {children}
        </Container>
      </div>
      <Footer />
    </div>
  )
}

export default Layout

Layout.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}
